import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import SimpleCard from "../components/simpleCard"

const useStyles = makeStyles(theme => ({
  banner: {
    overflow: "visible",
    marginTop: "-140px",
    paddingTop: "100px",
    backgroundColor: "#0a2540",
    transform: "skew(0deg, -6deg)",
  },
  bannerContent: {
    paddingTop: "150px",
    transform: "skew(0deg, 6deg)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "80px",
    },
  },
  manifesti: {
    paddingTop: "65px",
    paddingBottom: "45px",
  },
  tabsMenu: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

const ManifestiPage = ({ data }) => {
  const classes = useStyles()

  return (
    <Layout title="Manifesti">
      <div className={classes.root}>
        <section className={classes.banner}>
          <Container className={classes.bannerContent}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <h1
                  style={{
                    color: "#fe5f55",
                    fontFamily: "Montserrat",
                    fontDisplay: "swap",
                  }}
                >
                  Manifesti
                </h1>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </Grid>
          </Container>
        </section>
        <section className={classes.manifesti}>
          <Container>
            <Grid container spacing={5}>
              {data.allContentfulManifesto.nodes.map((manifesto, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  key={index}
                  id={manifesto.slug}
                >
                  <SimpleCard
                    type="manifesti"
                    title={manifesto.titolo}
                    descrizione={manifesto.descrizione}
                    slug={"/manifesti/" + manifesto.slug}
                    image={manifesto.media[0].media}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default ManifestiPage

export const query = graphql`
  {
    allContentfulManifesto(
      filter: { node_locale: { eq: "it-IT" } }
      sort: { fields: articoli___anno, order: DESC }
    ) {
      nodes {
        titolo
        slug
        descrizione
        squadre {
          nome
        }
        media {
          titolo
          descrizione {
            descrizione
          }
          media {
            fluid {
              src
            }
          }
        }
        articoli {
          titolo
        }
      }
    }
  }
`
